<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Transactions </span>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="transactions"
      item-key="id"
      class="table-rounded"
      :loading="loading"
      :search="search"
      disable-sort
      :server-items-length="pagination.total"
      :page-count="pagination.last_page"
      :options.sync="options"
      :page.sync="pagination.current_page"
      :items-per-page="pagination.per_page"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 200],
      }"
    >
      <template #[`item.amount`]="{item}">
        <span :class="color(item.type)">
          {{ (Intl.NumberFormat()).format(item.amount) }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  setup() {
    return {
      headers: [
        { text: 'AMOUNT', value: 'amount' },
        { text: 'STATUS', value: 'status' },
        { text: 'TYPE', value: 'type' },
        { text: 'CHANNEL', value: 'channel_type' },
        { text: 'DATE', value: 'created_at' },
      ],
      color(status) {
        if (status === 'cash_out') {
          return 'error--text'
        }

        return 'success--text text-darken-3'
      },
    }
  },
  data() {
    return {
      transactions: [],
      loading: true,
      search: '',
      errors: {},
      options: {},
      pagination: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
    },
    deep: true,
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    fetchApiData() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      this.$http
        .get(`/transactions?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.transactions = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
